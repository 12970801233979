import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./styles/main.css";
import Home from "./ui/home/Home";

function App() {
    return (
        <div className="flex flex-col">
            <div className="mx-auto w-screen">
                <Router>
                    <div>
                        <Route exact path="/" render={() => <Home />} />
                        {/* <Redirect exact path="/" /> */}
                    </div>
                </Router>
            </div>
        </div>
    );
}

export default App;
