import ukFlag from "../assets/country-flags/flag-united-kingdom.svg";
import auFlag from "../assets/country-flags/flag-australia.svg";
import inFlag from "../assets/country-flags/flag-india.svg";
import usFlag from "../assets/country-flags/flag-united-states-of-america.svg";

import {
    emmaWiltshireAu,
    loganCluniesAu,
    lucyPearsonAu,
    tajWilliamsAu,
    ankitaSinghalIn,
    nareshMedaIn,
    pradeepJainIn,
    priyankaBhatti,
    annGoddardUk,
    jamesCarrollUk,
    simonRodwellUK,
    susanTaylorUk,
    victoriaAshcroftUk,
    aliceSuarezUs,
    bonnieLeeUs,
    danielleScottUs,
    davidMcKainUs,
    jamesWilliamsUs,
    jasonTalbertUs,
    johnPerkinsUs,
    katyFloydUs,
    kimberlyArriagaUs,
    williamSmithUs,
} from "../assets/audio/index";

const ukVoices = [
    {
        id: "0a146401-c55e-4b77-af2c-836412e4ec5a",
        language: "English",
        country: "United Kingdom",
        type: "WaveNet",
        languagecode: "en-GB",
        name: "en-GB-Wavenet-A",
        ssmlgender: "FEMALE",
        fullname: "Ann Goddard",
        demoAudioUrl: annGoddardUk,
    },
    {
        id: "b3fd5b49-ebc9-458a-8e0a-948a637a10af",
        language: "English",
        country: "United Kingdom",
        type: "WaveNet",
        languagecode: "en-GB",
        name: "en-GB-Wavenet-D",
        ssmlgender: "MALE",
        fullname: "James Carroll",
        demoAudioUrl: jamesCarrollUk,
    },
    {
        id: "2a2f8ca5-83dc-482e-aab8-38d200b8a5c4",
        language: "English",
        country: "United Kingdom",
        type: "WaveNet",
        languagecode: "en-GB",
        name: "en-GB-Wavenet-B",
        ssmlgender: "MALE",
        fullname: "Simon Rodwell",
        demoAudioUrl: simonRodwellUK,
    },
    {
        id: "491bd61a-4504-47e8-b440-94444af4c1f7",
        language: "English",
        country: "United Kingdom",
        type: "WaveNet",
        languagecode: "en-GB",
        name: "en-GB-Wavenet-F",
        ssmlgender: "FEMALE",
        fullname: "Susan Taylor",
        demoAudioUrl: susanTaylorUk,
    },
    {
        id: "7fb8bdc3-f75e-4882-8c5e-1762aea113f4",
        language: "English",
        country: "United Kingdom",
        type: "WaveNet",
        languagecode: "en-GB",
        name: "en-GB-Wavenet-C",
        ssmlgender: "FEMALE",
        fullname: "Victoria Ashcroft",
        demoAudioUrl: victoriaAshcroftUk,
    },
];

const usVoices = [
    {
        id: "b64c411f-49f1-43ef-81d8-424ad9aeb6e2",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-F",
        ssmlgender: "FEMALE",
        fullname: "Alice Suarez",
        demoAudioUrl: aliceSuarezUs,
    },
    {
        id: "100692be-6280-4779-89d7-27dc57ac107f",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-E",
        ssmlgender: "FEMALE",
        fullname: "Bonnie Lee",
        demoAudioUrl: bonnieLeeUs,
    },
    {
        id: "1b64134a-e416-4b88-ad63-1873256d5b41",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-G",
        ssmlgender: "FEMALE",
        fullname: "Danielle Scott",
        demoAudioUrl: danielleScottUs,
    },
    {
        id: "ffbe5f5d-42fa-4513-9e8c-66ca91356a53",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-A",
        ssmlgender: "MALE",
        fullname: "David McKain",
        demoAudioUrl: davidMcKainUs,
    },
    {
        id: "e0e9bae7-e141-4a77-b321-383a53dd25bb",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-D",
        ssmlgender: "MALE",
        fullname: "James Williams",
        demoAudioUrl: jamesWilliamsUs,
    },
    {
        id: "09172927-9953-4ec7-815a-07197a5c4557",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-J",
        ssmlgender: "MALE",
        fullname: "Jason Talbert",
        demoAudioUrl: jasonTalbertUs,
    },
    {
        id: "9c1445bb-250c-4a9b-a51e-b6e658b8c43c",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-B",
        ssmlgender: "MALE",
        fullname: "John Perkins",
        demoAudioUrl: johnPerkinsUs,
    },
    {
        id: "d0e4530b-bcb4-4a5f-ba5f-4a4b840e463b",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-H",
        ssmlgender: "FEMALE",
        fullname: "Katy Floyd",
        demoAudioUrl: katyFloydUs,
    },
    {
        id: "0046d69a-e66f-413d-9f09-bbec746b0b42",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-C",
        ssmlgender: "FEMALE",
        fullname: "Kimberly Arriaga",
        demoAudioUrl: kimberlyArriagaUs,
    },
    {
        id: "a2fb14ab-698c-4003-95ad-e8633408129b",
        language: "English",
        country: "United States",
        type: "WaveNet",
        languagecode: "en-US",
        name: "en-US-Wavenet-I",
        ssmlgender: "MALE",
        fullname: "William Smith",
        demoAudioUrl: williamSmithUs,
    },
];

const auVoices = [
    {
        id: "2299ed57-724a-4aa7-b34f-7bc168e49b22",
        language: "English",
        country: "Australia",
        type: "WaveNet",
        languagecode: "en-AU",
        name: "en-AU-Wavenet-C",
        ssmlgender: "FEMALE",
        fullname: "Emma Wiltshire",
        demoAudioUrl: emmaWiltshireAu,
    },
    {
        id: "f989af74-8372-4eb0-9e87-e78dba3b73de",
        language: "English",
        country: "Australia",
        type: "WaveNet",
        languagecode: "en-AU",
        name: "en-AU-Wavenet-A",
        ssmlgender: "FEMALE",
        fullname: "Lucy Pearson",
        demoAudioUrl: lucyPearsonAu,
    },
    {
        id: "0046f252-c141-401c-921f-fe7a4052d2b2",
        language: "English",
        country: "Australia",
        type: "WaveNet",
        languagecode: "en-AU",
        name: "en-AU-Wavenet-B",
        ssmlgender: "MALE",
        fullname: "Logan Clunies",
        demoAudioUrl: loganCluniesAu,
    },

    {
        id: "a9cf4614-15c6-4d62-b7cb-cf0341a08208",
        language: "English",
        country: "Australia",
        type: "WaveNet",
        languagecode: "en-AU",
        name: "en-AU-Wavenet-D",
        ssmlgender: "MALE",
        fullname: "Taj Williams",
        demoAudioUrl: tajWilliamsAu,
    },
];

const inVoices = [
    {
        id: "f44c2266-27ce-438d-b107-600b6caadf2e",
        language: "English",
        country: "India",
        type: "WaveNet",
        languagecode: "en-IN",
        name: "en-IN-Wavenet-D",
        ssmlgender: "FEMALE",
        fullname: "Ankita Singhal",
        demoAudioUrl: ankitaSinghalIn,
    },
    {
        id: "9642945e-e677-4bea-8fb4-a21651a82e50",
        language: "English",
        country: "India",
        type: "WaveNet",
        languagecode: "en-IN",
        name: "en-IN-Wavenet-B",
        ssmlgender: "MALE",
        fullname: "Naresh Meda",
        demoAudioUrl: nareshMedaIn,
    },
    {
        id: "2a1daf5b-ab59-4ab5-a3a1-850f35b4c439",
        language: "English",
        country: "India",
        type: "WaveNet",
        languagecode: "en-IN",
        name: "en-IN-Wavenet-C",
        ssmlgender: "MALE",
        fullname: "Pradeep Jain",
        demoAudioUrl: pradeepJainIn,
    },
    {
        id: "e5f56d72-67a3-4377-949f-bac65f1ea2f4",
        language: "English",
        country: "India",
        type: "WaveNet",
        languagecode: "en-IN",
        name: "en-IN-Wavenet-A",
        ssmlgender: "FEMALE",
        fullname: "Priyanka Bhatti",
        demoAudioUrl: priyankaBhatti,
    },
];

export const allVoices = [
    {
        label: "Australia",
        flag: auFlag,
        language: "English",
        options: auVoices,
    },
    {
        label: "India",
        flag: inFlag,
        language: "English",
        options: inVoices,
    },
    {
        label: "United Kingdom",
        flag: ukFlag,
        language: "English",
        options: ukVoices,
    },
    {
        label: "United States",
        flag: usFlag,
        language: "English",
        options: usVoices,
    },
];
