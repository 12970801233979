import React, { useState, useEffect, createContext } from "react";
import { Form, Field, useFormik, FormikProvider, ErrorMessage } from "formik";
import * as Yup from "yup";
import Player from "../../components/wavesurferplayer/Player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CountrySelect from "../../components/options/CountryOption";

export const SelectVoiceContext = createContext(null);

function Home() {
    const [isSending, setIsSending] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(true);
    const [mediaUrl, setMediaUrl] = useState();
    const [
        isVoiceSelectionErrorVisible,
        setVoiceSelectionErrorVisible,
    ] = useState(false);
    const [humanVoice, setHumanVoice] = useState({});

    useEffect(() => {
        setVoiceSelectionErrorVisible(false);
    }, [humanVoice]);

    const handleLoaded = (_) => {
        window.grecaptcha.ready((_) => {
            window.grecaptcha
                .execute("6LdfZtQZAAAAALt8qoYcKmUSgvj2XKmS3VEAy5S-", {
                    action: "homepage",
                })
                .then((token) => {
                    validateRecaptchaCode(token);
                });
        });
    };

    const validateRecaptchaCode = (token) => {
        setMediaUrl(null);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                humanKey: token,
            }),
        };

        fetch("https://api.netcrumb.com/validate-recaptcha", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const score = JSON.stringify(data.score);
                if (score < 0.5) {
                    setShouldShowButton(false);
                }
            })
            .catch((error) => {
                setShouldShowButton(false);
            });
    };

    const convertTextToVoice = (message) => {
        setMediaUrl(null);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                text: `<speak>${message}</speak>`,
                languageCode: humanVoice.languagecode,
                ssmlGender: humanVoice.ssmlgender,
                name: humanVoice.name,
            }),
        };

        fetch("https://api.netcrumb.com/free-text-to-speech", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setIsSending(false);
                const audioUrl = JSON.stringify(data.fileUrl);
                setMediaUrl(audioUrl.substring(1, audioUrl.length - 1));
            })
            .catch((error) => {
                // Do something with the error
            });
    };

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js?render=6LdfZtQZAAAAALt8qoYcKmUSgvj2XKmS3VEAy5S-";
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);
    }, []);

    const formik = useFormik({
        initialValues: {
            message: "",
        },
        onSubmit: async (values) => {
            if (Object.keys(humanVoice).length === 0) {
                setVoiceSelectionErrorVisible(true);
            } else {
                setIsSending(true);
                convertTextToVoice(values.message);
            }
        },

        validationSchema: Yup.object({
            message: Yup.string()
                .min(5, "Must be at least 5 characters")
                .required("At least 5 characters are required"),
        }),
    });

    const shouldShowError = () => {
        if (isVoiceSelectionErrorVisible) {
            return (
                <p className="text-xs text-red-600 lg:ml-10 lg:mt-6">
                    Please select a voice
                </p>
            );
        }
    };

    return (
        <SelectVoiceContext.Provider value={setHumanVoice}>
            <div className="bg-white h-full w-full">
                <div
                    className="g-recaptcha"
                    data-sitekey="_reCAPTCHA_site_key_"
                    data-size="invisible"></div>
                <nav className="h-20 flex items-center">
                    <img
                        src={require("../../assets/logo_netcrumb.png")}
                        className="ml-4 lg:ml-20 w-40"
                    />
                </nav>
                <div className="text-center mx-10 my-20">
                    <h1 className="text-2xl md:text-4xl font-bold">
                        Natural Voices Text To Speech Converter
                    </h1>
                    <p className="text-md">
                        Turn any text into lifelike audio in just a few seconds.
                        Powered by Artificial Intelligence.
                    </p>
                </div>
                <div className="pb-20 mx-4 lg:mx-0">
                    <div className="w-full lg:w-4/5 h-screen md:h-full bg-gray-100 lg:mx-auto grid grid-rows-2 grid-cols-0 lg:grid-cols-2 border-2 border-gray-100 rounded-md md:mt-20 lg:py-10 p-4 lg:p-0">
                        <div className="lg:row-span-2">
                            {shouldShowError()}
                            <CountrySelect />
                            <FormikProvider value={formik}>
                                <Form className="flex flex-col my-6 lg:mr-20 lg:ml-10">
                                    <div className="text-xs text-red-600">
                                        <ErrorMessage name="message" />
                                    </div>
                                    <Field
                                        component="textarea"
                                        className="h-40 rounded-md border-gray-100  shadow-xs outline-none p-2"
                                        maxLength="280"
                                        id="message"
                                        name="message"
                                        placeholder="Enter your text..."
                                        type="message"
                                    />
                                    <label
                                        className="text-right text-xs text-gray-500 my-2"
                                        htmlFor="message">
                                        {formik.values.message.length} / 280 max
                                        characters
                                    </label>
                                    {shouldShowButton && (
                                        <button
                                            // disabled={isSending}

                                            className="w-full lg:w-40 h-10 flex justify-center items-center rounded text-white bg-blue-500 cursor-pointer hover:bg-blue-700 select-none focus:outline-none"
                                            label="Convert To Audio"
                                            type="submit">
                                            {isSending ? (
                                                <div className="h-full w-full flex flex-row items-center justify-center">
                                                    <FontAwesomeIcon
                                                        icon={faSpinner}
                                                        className="mr-4 animate-spin"
                                                    />
                                                    <p>Processing</p>
                                                </div>
                                            ) : (
                                                <div>Convert to Audio</div>
                                            )}
                                        </button>
                                    )}
                                </Form>
                            </FormikProvider>
                        </div>
                        <div className="lg:row-span-2 mt-10 lg:mt-0 border-t-2 lg:border-l-2 lg:border-t-0">
                            {/* <Player url="https://storage.googleapis.com/download/storage/v1/b/ttv-prod-2020.appspot.com/o/a53083e9-7d4e-4fc0-8e94-a6cdeda2fe32_output.mp3?generation=1603205589935130&alt=media" /> */}
                            {mediaUrl && <Player url={mediaUrl} />}
                        </div>
                    </div>
                </div>
                {/* <footer className="h-32 text-center bg-blue-400">
                <h1>Copyright 2020 @ Netcrumb</h1>
            </footer> */}
            </div>
        </SelectVoiceContext.Provider>
    );
}

export default Home;
