import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faDownload } from "@fortawesome/free-solid-svg-icons";

const useAudio = (url, waveformRef) => {
    const [playing, setPlaying] = useState(false);
    const [waveSurf, setWaveSurf] = useState();

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        const audio = WaveSurfer.create({
            container: waveformRef.current,
            backend: "MediaElement",
            waveColor: "#edf2f7",
            progressColor: "#4299e1",
            cursorWidth: 0,
            barHeight: 2,
            barWidth: 3,
            barRadius: 3,
            barGap: 0.2,
        });

        audio.load(url);

        setWaveSurf(audio);
    }, [url]);

    useEffect(() => {
        if (waveSurf !== undefined) {
            waveSurf.on("finish", function () {
                waveSurf.stop();
                setPlaying(false);
            });

            waveSurf.on("pause", function () {
                setPlaying(false);
            });

            console.log("Play");
            // if (playing) {
            let promise = waveSurf.playPause();
            if (promise !== undefined) {
                promise
                    .then((_) => {
                        console.log(waveSurf.isPlaying());
                        // Automatic playback started!
                    })
                    .catch(function (error) {
                        console.log(error);
                        // Automatic playback failed.
                        // Show a UI element to let the user manually start playback.
                    });
            }
            // } else {
            //     waveSurf.pause();
            // }
        }
    }, [playing]);

    return [playing, toggle];
};

const Player = ({ url }) => {
    const waveformRef = useRef(null);
    const buttonRef = useRef(null);
    // const [playing, toggle] = useAudio(url, waveformRef);

    const [isPlaying, setIsPlaying] = useState(false);

    const [audio, setAudio] = useState();

    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState();

    useEffect(() => {
        const audio = WaveSurfer.create({
            container: waveformRef.current,
            backend: "MediaElement",
            waveColor: "#edf2f7",
            progressColor: "#4299e1",
            cursorWidth: 0,
            barHeight: 2,
            barWidth: 3,
            barRadius: 3,
            barGap: 0.2,
        });

        audio.load(url);

        setAudio(audio);
    }, [url]);

    if (audio !== undefined) {
        audio.on("ready", () => {
            var totalTime = audio.getDuration();
            setTotalTime(Math.round(totalTime));
        });
    }

    function play() {
        if (audio !== undefined) {
            audio.on("audioprocess", function () {
                var currentTime = audio.getCurrentTime();
                // setCurrentTime(Math.round(currentTime.toFixed(1)));
                setCurrentTime(Math.floor((currentTime % 3600) % 60));
            });

            audio.on("finish", function () {
                audio.stop();
                setIsPlaying(false);
                setCurrentTime(0);
            });

            audio.on("pause", function () {
                setIsPlaying(false);
            });

            var promise = audio.playPause();
            if (promise !== undefined) {
                promise
                    .then((_) => {
                        setIsPlaying(true);
                        // Automatic playback started!
                    })
                    .catch(function (error) {
                        // Automatic playback failed.
                        // Show a UI element to let the user manually start playback.
                    });
            }
        }
    }

    function createTime(value) {
        if (value < 10) {
            return `0:0${value}`;
        } else if (value > 10 || value < 60) {
            return `0:${value}`;
        } else {
            return value;
        }
    }

    return (
        <div className="lg:mx-4 bg-white rounded-md">
            <div ref={waveformRef} className="border-b"></div>
            <div className="flex flex-row p-4 justify-between items-center">
                <button
                    onClick={play}
                    ref={buttonRef}
                    className="bg-orange-600 hover:bg-orange-700 rounded-full h-12 w-12 flex items-center justify-center text-white select-none focus:outline-none">
                    {isPlaying ? (
                        <FontAwesomeIcon icon={faPause} />
                    ) : (
                        <FontAwesomeIcon icon={faPlay} />
                    )}
                </button>
                {totalTime && (
                    <p className="text-sm">
                        {createTime(currentTime)} / {createTime(totalTime)}
                    </p>
                )}
                <a
                    href={url}
                    download
                    className="text-sm float-right pr-2 text-gray-700 hover:text-gray-800 select-none focus:outline-none inline-block">
                    <FontAwesomeIcon className="mr-1" icon={faDownload} />
                    Download
                </a>
            </div>
        </div>
    );
};

export default Player;
