import React, { useContext, useState, useEffect } from "react";
import Select, { components } from "react-select";
import { SelectVoiceContext } from "../../ui/home/Home";
import { allVoices } from "../../assets/allvoices";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    audio.addEventListener("ended", () => setPlaying(false));

    useEffect(() => {
        playing ? audio.play() : audio.pause();
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, [playing]);

    return [playing, toggle];
};

const Option = (props) => {
    const fullName = props.data.fullname;
    const demoAudioUrl = props.data.demoAudioUrl;

    const [playing, toggle] = useAudio(demoAudioUrl);

    return (
        <div className="flex flex-row relative items-center justify-end py-2">
            <components.Option {...props}>
                <p className=" font-medium">{fullName}</p>
            </components.Option>

            <button
                className=" absolute mr-10 ml-4 p-4 text-orange-500 hover:text-orange-700 cursor-pointer focus:outline-none"
                onClick={toggle}>
                {playing ? (
                    <FontAwesomeIcon
                        icon={faPause}
                        className="focus:outline-none"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faPlay}
                        className="focus:outline-none"
                    />
                )}
            </button>
        </div>
    );
};

const formatGroupLabel = (data) => (
    <div className="flex flex-row items-center">
        <span>
            {data.label} - {data.language}
        </span>

        <img src={data.flag} className="ml-6 w-6 h-6" />
    </div>
);

const CountrySelect = () => {
    const dispatch = useContext(SelectVoiceContext);
    const [voice, setVoice] = useState({});

    function handleSelection(item) {
        setVoice(item);
        dispatch(item);
    }

    return (
        <Select
            className="lg:mb-6 lg:mt-2 lg:mr-20 lg:ml-10"
            placeholder="Select a voice"
            components={{ Option }}
            isOptionSelected={(option) => {
                return voice.languageCode === option.languageCode
                    ? false
                    : true;
            }}
            options={allVoices}
            formatGroupLabel={formatGroupLabel}
            onChange={(e) => handleSelection(e)}
            getOptionLabel={(option) => {
                return `${option.fullname}, ${option.country}, speaks ${option.language}`;
            }}
        />
    );
};

export default CountrySelect;
